<template>

  <router-view @loaded="batchDataLoaded" v-fade-in="!detailsLoading" ></router-view>

  <container :loading="loading">
    <template #heading>All Courses</template>
    <div class="grid md:grid-cols-3 gap-4 py-4 w-full" v-fade-in="!loading" >
      <batch-item v-for="item in  ownBranchItems" :item="item" :key="item.program_id" :enroll="false" />
    </div>
  </container>

  <container  v-fade-in="!loading" :loading="loading">
    <template #heading>Other Branch Courses</template>
    <div class="grid md:grid-cols-3 gap-4 py-4 w-full">
      <batch-item v-for="item in  otherBranchItems" :item="item" :key="item.program_id" :enroll="false"/>
    </div>
  </container>

</template>

<script>
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import BatchItem from "../home/components/batch-item";
import Container from "../../layouts/container";
export default {
  name: "Course",
  components: {Container, BatchItem, Spinner, Overly},
  data() {
    return {
      items:[],
      detailsLoading: true,
      loading: true
    }
  },
  computed:{
    ownBranchItems(){
      //return [];
      return Array.isArray(this.items) ? this.items.filter( item => item.branch_is_own === true ):[];
    },
    otherBranchItems(){
      //return [];
      return Array.isArray(this.items) ? this.items.filter( item => item.branch_is_own === false ):[];
    },
  },
  methods:{
    batchDataLoaded(){
      this.detailsLoading = false;
      scrollTo( '#course-details', 800 );
    },
    loadItems() {
      this.$store
          .dispatch('public/availableBatches', {
            params: {

              branchFiltering: 'false',
              course_id : this.$route.query.course_id,
              per_page: 100
            },
            success: (response) => {
              //console.log( response.data.available_batches );
              this.items = response.data.available_batches;
            },
            error: () => {},
          })
          .finally(() => {
            this.loaded = true;
            this.loading = false
          });
    },
  },
  created( ) {
    this.loadItems();
    //console.log( 'this.$store.flashMessage', this.$store.state.flashMessage );
  }

};
</script>

<style scoped>
</style>
